import React from "react";
import { makeStyles } from "tss-react/mui";

import MuiSvgIcon, { type SvgIconProps } from "@mui/material/SvgIcon";

/**
 * Types
 */
type SvgIconSize = "xs" | "sm" | "sm-md" | "m" | "md" | "lg" | "xl" | "xxl" | "initial";

export interface Props extends SvgIconProps {
  size?: SvgIconSize;
}

/**
 * Styles
 */
const useStyles = makeStyles()({
  sizeXs: {
    width: "12px",
    height: "12px",
  },
  sizeSm: {
    width: "16px",
    height: "16px",
  },
  sizeSmMd: {
    width: "18px",
    height: "18px",
  },
  sizeM: {
    width: "20px",
    height: "20px",
  },
  sizeMd: {
    width: "24px",
    height: "24px",
  },
  sizeLg: {
    width: "28px",
    height: "28px",
  },
  sizeXl: {
    width: "32px",
    height: "32px",
  },
  sizeXxl: {
    width: "48px",
    height: "48px",
  },
  sizeInitial: {
    width: "auto",
    height: "auto",
  },
});

const SvgIcon: React.FC<Props> = ({ size = "md", className, ...props }: Props) => {
  const { classes, cx } = useStyles();

  return (
    <MuiSvgIcon
      {...props}
      className={cx(
        {
          [classes.sizeXs]: size === "xs",
          [classes.sizeSm]: size === "sm",
          [classes.sizeSmMd]: size === "sm-md",
          [classes.sizeM]: size === "m",
          [classes.sizeMd]: size === "md",
          [classes.sizeLg]: size === "lg",
          [classes.sizeXl]: size === "xl",
          [classes.sizeXxl]: size === "xxl",
          [classes.sizeInitial]: size === "initial",
        },
        className && className,
      )}
    />
  );
};

export default SvgIcon;
